<template>
  <documentForm />
</template>

<script>
import documentForm from "./documentForm.vue";

export default {
  components: { documentForm },
};
</script>

<style></style>
